import React from "react";

function Privacy() {
    return (
        <div className="privacy">
            <div className="container" style={{margin: '15px'}}>
                <h1>Privacy Policy - United States</h1>
                <h1>RushApp Consumers</h1>
                Effective Date: September 11, 2021<br/>

                <p>
                RushApp, Inc. (“RushApp,” “we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy (“Policy”) explains how we collect, use, disclose and protect Personal Information (as it is defined below) of our customers and users.
                It applies to all Personal Information collected by us on www.rushapp.com (the “Site”), the RushApp mobile application, and any other websites or mobile applications that link to this Policy, as well as during any written, electronic, and oral communications (collectively, the “Services”).
                RushApp may collect Personal Information to help you place and receive orders and to improve the RushApp service.
                </p>

                <p>
                Your access to and use of our Services are subject to our Terms and Conditions, which define some of the terms used throughout this Policy.
                Please make sure that you have carefully read and understand the Terms and Conditions before you use our Services.
                By using our Services, you accept the Terms and Conditions, and accept our privacy practices described in this Policy.
                If you do not feel comfortable with any part of this Policy or our Terms and Conditions, you must not use or access our Services.
                </p>
                <p>
                The Company uses a network of independent third-party contractors (“Rushers” or “Couriers”) who provide delivery services to our users and food service providers.
                Rushers should refer to the Rusher Privacy Statement for information on how RushApp collects and uses Rushers’ Personal Information.
                </p>
                <p>
                Our Services may change from time to time.
                As a result, we reserve the right to update or modify this Policy at any time and from time to time without prior notice.
                If we make any material changes to the way we process your Personal Information, we will notify you before such changes are effective.
                If you object to any changes, you must cease using our Services.
                We recommend you review this Policy periodically.
                This Policy became effective on the date indicated at the top of this page.
                Your continued use of our Services after any changes or revisions to this Policy indicates your agreement to the terms of the revised Policy.
                </p>

                <p>1.   Collection of Personal Information</p>

                <p>
                When you use our Services, we may collect or receive Personal Information about you to provide our services and operate our business.
                The types of Personal Information that we may collect about you are:
                </p>

                <p>a. Information You Provide to Us Directly</p>

                <p>
                You may provide your Personal Information to us when you use our Services, including when you register with RushApp, search within or place orders through our Services, contact us with inquiries, respond to surveys, or otherwise access our Services.
                This information may include:
                </p>

                User Profile – We collect profile information associated with your Customer RushApp account. This includes information such as name, email address, delivery address, and phone number.
                Order Details – When you place an order through our Services we collect information related to that order. This may include information such as items purchased, special instructions, date and time of order, and subtotal.
                Payment Information – Payment information collected such as bank name and payment method.
                Surveys – From time-to-time, we invite our customers and users to participate in surveys or contests. When a customer or a user participates, we may request certain Personal Information such as name and email address.
                b. Information Received From You as Part of Your Communications

                <p>
                When you use our Services (for example on our Site), complete electronic forms, communicate with Rushers or contact us, by online chat, email, phone or text, we may automatically collect and store certain information about you and the activity you engaged in, for example: your name and contact information; your order details; information that you voluntarily provide to us; the nature of your communication; the purpose of the interaction, and the action we took in response to your inquiry or request.
                </p>

                <p>c. Information Collected Automatically</p>

                <p>
                We also may receive and store certain information about you and your device(s) automatically when you access or use our Site and Services.
                This information may include:
                </p>

                Technical Information – We collect technical information associated with your activity on our Site and may include information related to your browser and operating system, IP address (the Internet address of your computer) unique device identifiers, and other information such as your device type. We use such information for diagnostic and statistical purposes and to administer our Services.
                Site Usage Information – We collect information to better understand customer traffic patterns and Site usage. This may include the webpage that you were visiting before accessing our Site or mobile application, the pages or features of our Site or mobile application that you browsed to inform us which part of our Site, app and Services you visit and how much time you spend there. This information is used to analyze and improve our Site and to provide our customers with a better user experience.
                Site Preferences – We collect information about your preferences to make your use of the Site more productive through the use of cookies.
                d. Location Information

                <p>
                When you use the Services, we may collect and store general location information (such as IP address). 
                If you permit the Services to access location services through the permission system used by your mobile operating system (“Platform”) or browser, we may also collect and store the precise location of your device when the RushApp app is running in the foreground or background of your device. 
                This information may be used to help you select your delivery address, to provide more accurate deliveries of your orders, to provide recommendations to you, and to enhance your user experience and improve the services. 
                You can choose whether to enable the location tracking feature through the settings on your device or Platform or when prompted by the RushApp mobile app. 
                If you choose to disable the location feature, the Rusher will not receive precise location information from your device, which may compromise the accuracy of deliveries in some situations, for instance, if you are located in a large area, such as a park.
                </p>

                <p>e. Information Related to the Referral Program</p>

                <p>
                RushApp allows users to invite friends and family to sign up for our Services.
                Sometimes we offer a promotional value for every friend or family member who signs up and places an order through your referral link.
                To help you do this, we may request you to grant us access to your mobile device address book.
                You can then direct us to send referral emails to contacts you select in your address book. By sending a referral, you also consent to RushApp sending your public profile image to the contacts you select to receive a referral link.
                By choosing to invite a friend you represent that you have received the friend’s consent to provide his or her name and email address with us.
                RushApp will use that information to send an email inviting him or her to visit the site and will store that information to track the success of the referral program and may send marketing emails.
                You can also send an SMS with a referral link to your contacts.
                If you use this option, you understand that RushApp does not control the timing, content, or recipients of these SMS messages.
                </p>

                <p>
                Users may not send, publish, or share any false, misleading, or deceptive communications in connection with participation in the Referral Program.
                Such actions may violate various federal, state, and/or international laws, including, but not limited to, the regulations and policies promulgated by the Federal Trade Commission.
                Users may not send any referral emails, SMS messages, or other communications to children under the age of 13.
                </p>

                <p>
                If you are referred to RushApp through one of our referral programs, or if someone attempts to refer you, we may share your name, whether you have already signed up for RushApp, whether you are a new customer, and whether you have placed a qualifying order with RushApp with the person(s) who referred you.
                </p>

                <p>f. Personal Information from Publicly Available Sources and Third Parties</p>

                <p>
                We may collect or receive Personal Information about you from publicly available sources, social network providers, marketing partners, and/or third parties.
                This may include:
                </p>

                Social Network Information – We may receive information about you from other services. For example, we receive certain information from Facebook when you use their service to login to RushApp.
                Demographic Information – This characteristic information could include information such as income, marital status, spending habits, and other information pertaining to customers.
                2.   Use of Cookies and Other Tracking Technologies

                <p>
                We use cookies, web beacons, pixels, session replay/screen capture, and similar technologies to collect information and personalize your experience with our Services.
                When we use session replay technology, we may collect on a real-time basis information on how you use and navigate the Services.
                This may include mouse movements and how you scroll through the Services, but we do not collect keystroke data.
                Additional information on other technologies we may use is set forth below.
                </p>

                <p>a. Cookies</p>

                <p>
                Cookies are small web files that a site or its provider transfers to your device’s hard drive through your web browser that enables the site’s or provider’s system to recognize your browser and remember certain information.
                </p>

                <p>
                How We Use Cookies – We use first-party and third-party cookies for the following purposes:
                </p>

                to make our Services function properly;
                to improve our Services;
                to recognize you when you return to RushApp and to remember information you have already provided, such as items already in your order cart;
                to collect information about your activities over time and across third party websites or other online services in order to deliver content and advertising tailored to your interests; and
                to provide a secure browsing experience during your use of our Services.
                How to Manage Cookies – You may disable the use of cookies by modifying your browser settings. If you choose to disable cookies you may not be able to fully experience the interactive features of our Services. For more information on cookies and how to disable cookies visit www.ftc.gov/ftc/cookies.shtm or https://www.usa.gov/optout_instructions.shtml.

                <p>b. Web Beacons</p>

                <p>
                Web beacons, also known as web bugs, pixel tags or clear GIFs, are tiny graphics with a unique identifier that may be included on our Site to deliver or communicate with cookies, in order to track and measure the performance of our Site and Services, monitor how many web visitors we have, and to monitor the effectiveness of our advertising.
                Unlike cookies, which are stored on the user’s hard drive, web beacons are typically embedded invisibly on webpages (or in an email).
                </p>

                <p>c. Online Analytics and Advertising Technologies</p>

                <p>
                We and our third-party vendors may use automated technologies (including cookie identifiers on our Site), along with other collected information, to tailor ads or deliver content when you are on our Services or on other devices, apps or websites.
                </p>

                <p>d. Interest-Based Advertising</p>

                <p>
                We (or our service providers) may use the information we collect, for instance, IP addresses and unique mobile device identifiers, to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, in order to better tailor content and features and provide you with a seamless experience across devices.
                If you wish to opt out of cross device tracking for purposes of interest-based advertising, you may do so through your device settings.
                We may also use cross-device targeting to help identify our users and serve advertising.
                This type of advertising is often called “interest-based” or “personalized” advertising—and when it occurs on mobile apps, “cross-app” advertising.
                </p>

                <p>
                You can learn more about interest-based advertising and how to opt-out of receiving tailored advertising by visiting (i) the Network Advertising Initiative’s Consumer Opt-Out link or (ii) the Digital Advertising Alliance’s Consumer Opt-Out link.
                To opt out of Google Analytics for display advertising or customize Google display network ads, you can visit the Google Ads Settings page.
                </p>

                <p>
                Please note that even if you opt-out, you may still receive advertisements from us.
                However, in that case, the advertising will not be tailored to your interests.
                Also, we do not control any of the above opt-out links or whether any particular company chooses to participate in these opt-out programs.
                </p>

                <p>e. Mobile Applications</p>

                <p>
                Depending on your permissions, we may receive your Personal Information from your Internet service and mobile device providers.
                Users of mobile devices who do not want to receive interest-based advertising may opt-out in several ways.
                Learn more about your choices for mobile devices by visiting http://www.aboutads.info/appchoices.
                Each operating system, (iOS for Apple phones, Android for Android devices, and Windows for Microsoft devices) provides its own instructions on how to prevent the delivery of tailored in-application advertisements.
                You should view your device or system “Settings” to determine how you can opt out of use of your device ID for “cross-app” personalized advertising.
                </p>

                <p>3.   Use of Your Personal Information</p>

                <p>We may use the information we collect or receive about you for various purposes.</p>

                <p>a. To Provide You With the Services</p>

                <p>
                We use your information to provide you the Services.
                For example, we use credit card information to complete a transaction or an address to allow the Rusher to fulfill your delivery.
                If the applicable information is to be provided or Service is to be performed by a third party, then we will disclose the applicable Personal Information to the third party providing the information or performing applicable Services.
                Your information may be available or provided to third-party service providers and that are contractually obligated to protect your information as disclosed in this Policy.
                </p>

                <p>b. To Maintain, Improve, and Personalize the Services</p>

                <p>
                We use your information for our everyday business operations such as auditing, administration of the Services, forum management, fulfillment, and analytics.
                Your information may also be used to improve the content and/or functionality of the Services.
                We may use your information to help us improve offerings for RushApp and/or our Merchants.
                We also use your information to personalize your experience.
                For example, we may personalize the content and features you see when visiting our Services.
                In addition, we may personalize advertisements, marketing communications, and recommendations to make them more tailored to your interests.
                </p>

                <p>c. To Communicate with You</p>

                <p>
                We use your information to communicate with you.
                For example, we may send you text messages or other notifications about the status of your orders and deliveries.
                We may also contact you with promotional offerings or other communications that may be of interest to you.
                If we send you marketing emails about services that may interest you, each email will contain instructions permitting you to “opt-out” of receiving future email marketing communications.
                </p>

                <p>
                In the event you contact us, we use certain information to respond to your questions and assist you with any issues that may arise in your use of the Services.
                If you send text messages to a Rusher, using the telephone number for that Rusher available on the Services, we may use a third-party service provider to track and analyze these text messages.
                We track these text messages for fraud prevention, to ensure appropriate charging of fees, to enforce our Terms and Conditions, and for quality and training purposes.
                As part of this process, RushApp and its service provider will receive in real time and store data about your text message, including the date and time of the text message, your phone number, and the content of the text message.
                You will not be able to opt out of transactional text messages between you and Rushers.
                </p>

                <p>d. For Account and Network Security Purposes</p>

                <p>
                We care about keeping you secure and safe while using our Services.
                Keeping you safe requires us to process your Personal Information, such as your device information, activity information and other relevant information.
                We use such information to combat spam, malware, malicious activity or security risks; improve and enforce our security measures; and to monitor and verify your identity so that unauthorized users do not gain access to your information.
                </p>

                <p>e. To Maintain Legal and Regulatory Compliance</p>

                <p>
                Our Services are subject to certain laws and regulations which may require us to process your Personal Information.
                For example, we process your Personal Information to pay our taxes, to fulfill our business obligations, ensure compliance with employment and recruitment laws or as necessary to manage risk as required under applicable law.
                </p>

                <p>f. To Enforce Compliance with Our Terms and Conditions and Policies</p>

                <p>
                When you access or use our Services, you are bound to our Terms and Conditions and this Policy.
                To ensure you comply with them, we process your Personal Information by actively monitoring, investigating, preventing and mitigating any alleged or actual prohibited, illicit or illegal activities on our Services.
                We also process your Personal Information to: investigate, prevent or mitigate violations of our internal terms, agreements or policies; enforce our agreements with third parties and business partners.
                </p>

                <p>g. To Protect You, Others, and Our Business</p>

                <p>
                We use your information to protect you, others, and our business, including, without limitation, using information for fraud prevention, for enforcement of our Terms of Service, to comply with the law, and to protect against security risks.
                </p>

                <p>h. For Our Business Purposes</p>

                <p>
                We may use your information for any other purpose disclosed to you at the time we collect or receive the information, or otherwise with your consent.
                </p>


                <p>4.   Sharing of Personal Information with Third Parties</p>

                <p>We share your Personal Information as described below.</p>

                <p>a. Service Providers</p>

                <p>
                We may share your information with our third-party service providers for certain business purposes.
                This information is provided in order for them to provide us services such as payment processing, advertising services, marketing partners, web analytics, data processing, IT services, customer support and other services.
                These third-party service providers have access to your Personal Information only for the purpose of performing services on our behalf and are expressly obligated not to disclose or use your Personal Information for any other purpose.
                </p>

                <p>b. Rushers and Merchants</p>

                <p>
                To help provide you with a high-quality delivery we also may share your information, including your Personal Information and order details, with the Rushers who complete your requested deliveries.
                Similarly, we may share information with Merchants to facilitate deliveries such as your first name and last initial and order information related to your orders with RushApp merchants.
                </p>

                <p>c. Related Entities</p>

                <p>
                We may share your information with our affiliates (entities that control or are under the control of RushApp) for business purposes.
                This will include related entities that utilize RushApp’s technology.
                We will require these entities to comply with the terms of this Policy with regard to their use of your information.
                </p>

                <p>
                For purposes of clarity, RushApp and related entities may share certain functionalities such as a uniform login experience where users of both applications will need to use a single username and password.
                </p>

                <p>d. When Required by Law</p>

                <p>
                We recognize that information related to your orders could contain private information.
                However, we may be required to disclose your information to third parties including law enforcement agencies when required to protect and defend our legal rights, protect the safety and security of users of our Services, prevent fraud, comply with the law, respond to legal process, or a request for cooperation by a government entity.
                We have developed a process that strikes a balance between protecting customer privacy and responding to valid law enforcement requests that can be found at https://www.rushapp.com/lert/.
                </p>

                <p>e. Corporate Transactions</p>

                <p>
                In the event of sale, transfer, merger, reorganization, dissolution, or similar event we may transfer your information to one or more third parties as part of that transaction, including during the negotiation of an actual or contemplated transaction.
                </p>

                <p>f. With Your Consent</p>

                <p>We also may share or disclose your information with your consent or at your direction.</p>

                <p>5.  Security</p>

                <p>
                RushApp has implemented administrative, technical, and physical security controls that are designed to safeguard Personal Information.
                However, no online activity is ever fully secure or error-free.
                While we strive to protect your information, we cannot guarantee that your Personal Information is absolutely secure.
                Please keep this in mind when disclosing any information to RushApp.
                </p>

                <p>
                Please recognize that protecting your Personal Information is also your responsibility.
                We urge you to take every precaution to protect your information when you are on the Internet, or when you communicate with us and with others through the Internet.
                Change your passwords often, use a combination of letters and numbers, and make sure you use a secure browser.
                If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account might have been compromised), or if you suspect someone else is using your account, please let us know immediately by contacting us as indicated in the Contact Us section below.
                </p>

                <p>6.  Data Retention</p>

                <p>
                We will retain your Personal Information for as long as your account is active or as needed to provide you services and to maintain a record of your transactions for financial reporting purposes.
                We will also retain and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </p>

                <p>7.  Third-Party Sites</p>

                <p>
                Some of the functionality may be provided by third parties and the Services may link to other third-party websites that are not controlled by RushApp. These third parties are not under our control and RushApp is not responsible for their privacy policies or practices. If you provide any Personal Information to any third party or through any such third-party website, we recommend that you familiarize yourself with the privacy policies and practices of that third party.
                </p>

                <p>8.  Social Media Usage</p>

                <p>
                You may choose to enable or log in to our Services via various online services, including social networking services like Facebook and Google.
                Our Services also may enable you to access social networking services such as Facebook, Twitter, or Instagram (collectively, “Social Network”) directly or indirectly through our Services.
                </p>

                <p>
                When you link a Social Network account to RushApp or log into our Services using your Social Network account, we may collect relevant Personal Information necessary to enable our Services to access that Social Network and your information contained within that Social Network.
                We also may share your information with the operator of that Social Network to facilitate or enhance delivery of that Social Network or other services to you.
                A Social Network may provide us with access to certain information that you have provided to them, and we will use, store, and disclose such information in accordance with this Policy.
                Additionally, a Social Network may collect Personal Information and information about your use of our Site or Services automatically.
                The manner in which a Social Network collects, uses, stores, and discloses your information is governed by the policies of such third parties and RushApp shall have no liability or responsibility for the privacy practices or other actions of any Social Network that may be enabled within our Services.
                </p>

                <p>
                You may also have the option of posting your activities on our Services and other content to a Social Network when you access content through our Services.
                For example, you may post to Facebook that you placed an order with RushApp.
                Keep in mind that your usage of Social Network features is governed by applicable Social Networks and not by RushApp, and may be visible or accessible to the public.
                </p>

                <p>9.   Children’s Information</p>

                <p>
                Our Services are not intended for children under 13 years of age and we do not knowingly collect Personal Information from children under the age of 13.
                If you are a parent or guardian of a child under the age of 13 and believe he or she has disclosed personally identifiable information to us please contact us at privacy@RushApp.com.
                A parent or guardian of a child under age 13 may review and request deletion of the child’s Personal Information.
                </p>

                <p>10.  Your Choices</p>

                <p>
                We provide our customers and their authorized agents with the ability to access and delete Personal Information.
                In order to exercise these rights you must login to your account to confirm your identity, which helps us ensure that Personal Information is only made accessible to appropriate parties.
                Customers will not receive discriminatory treatment for exercising their rights and can return to the service after deleting their information.
                </p>

                <p>a. Your Rights</p>

                <p>
                This section lists the privacy-related rights (“Rights”) we extend to all RushApp customers.
                Your right to know and right to deletion are not absolute and are subject to certain exceptions.
                For instance, we cannot disclose specific pieces of Personal Information if the disclosure would create a substantial, articulable, and unreasonable risk to the security of the Personal Information, your account with us or the security of our systems.
                </p>

                <p>
                Right to Know – You have the right to know the Personal Information we collect, use, disclose, and sell about you.
                Right to Deletion – You have the right to request that we delete any Personal Information we have collected from you or maintain about you. We may save Personal Information when permitted by applicable law including, without limitation, when the information is needed for a legal purpose. If you are a California consumer, we may retain your Personal Information as necessary to: (1) complete the transaction for which the personal information was collected, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between RushApp and you; (2) detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity; (3) debug to identify and repair errors that impair existing intended functionality; (4) exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; (5) comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12 of Part 2 of the Penal Code; (6) engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when RushApp’s deletion of the information is likely to render impossible or seriously impair the achievement of such research, if you have provided informed consent; (7) to enable solely internal uses that are reasonably aligned with the expectations of you based on your relationship with RushApp; (8) to comply with a legal obligation; or (9) to otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.
                Right to Rectification – If you are a registered RushApp user you may modify certain of your Personal Information by logging into our Site using your username and password and visiting the “Account” page. Registered RushApp users may also access the “Account” page to manage their subscriptions.
                Right to Opt-Out of the Sale of Your Personal Information – You have the right to opt-out of having your Personal Information sold. The California Privacy Rights Act (“CPRA”) amends the California Consumer Privacy Act to broadly define “sale” in a way that may include allowing third parties to receive certain information such as cookie identifiers, IP addresses and/or browsing behavior to add to a profile about your device, browser or you. Such profiles may enable delivery of interest-based advertising by such third parties within their platform or on other sites. Depending on how you use the Services, we may share the following categories of information for such interest-based advertising which may be considered a sale (as defined by the CPRA): (1) device information and identifiers, such as IP address and unique advertising identifiers and cookies; and (2) connection and usage information, such as browsing history or app usage. Although this obligation of the CPRA does not become effective until January 1, 2023, you may opt out of such tracking technologies by following the instructions in Section 2 above.
                Right to Non-Discrimination – RushApp will not discriminate against those who exercise their Rights. Specifically, if you exercise your Rights, we will not deny you goods or Services, charge you different prices or rates for goods or Services or provide you a different level or quality of goods or Services.
                </p>

                <p>b. Asserting Your Rights</p>

                <p>
                If you have a RushApp account, you may exercise your right to know or your right to deletion by clicking here for RushApp.
                To verify your identity, you must log-in to your account prior to accessing or deleting your information.
                If you have a RushApp customer account, then the information associated with both accounts will be deleted to the extent we can identify that the accounts are owned by the same user.
                </p>

                <p>
                If you do not have a RushApp account, you may exercise your right to know or your right to deletion by emailing your request to us at privacy@rushapp.com from the email address that you believe is associated with RushApp, with the subject line “Non-user Data Request” or calling us at 1-509-306-9994.
                For safety and to otherwise prevent fraud, we may ask that you provide us with certain personal information that’s reasonably necessary for us to confirm your identity.
                </p>

                <p>
                You may also designate an authorized agent to make a request to know or a request to delete.
                In order to be able to act, authorized agents have to submit written proof that they are authorized to act on your behalf, or have a power of attorney.
                We may deny requests from authorized agents who do not submit proof that they have been authorized by you to act on their behalf.
                </p>

                <p>11.  California Residents</p>

                <p>
                Pursuant to the California Consumer Privacy Act of 2018, below is a summary of the Personal Information we collected from consumers, the reason we collected the Personal Information, where we obtained the Personal Information we collected, and the third parties with whom we may share consumer Personal Information.
                The section references relate to the sections above in this Policy.
                </p>

                <p>
                As described further in our Privacy Policy, in the preceding twelve months, we or our service providers may have collected the below categories of personal information for business or commercial purposes:
                </p>

                <p>
                Identifiers such as a name, address, unique personal identifier, email, phone number;
                Customer records such as signature;
                Commercial information such as records of products or services purchased, obtained, or considered;
                Internet or other electronic network activity information, including browsing history and search history;
                Geolocation data that is sufficient to identify a precise physical location;
                Sensory data, such as audio, electronic, visual, or other similar information;
                Professional or employment-related information;
                Education information; and
                Inferences about preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                We collect the categories of personal information identified above from the following sources: (1) directly from you; (2) through your use of the Services; (3) affiliates; and (4) third parties such as information received from our referral program, social networking services, publicly available sources, and marketing partners.
                </p>

                <p>As explained above, we collect the categories of personal information identified above for the following business and commercial purposes:</p>

                <p>
                To provide you with the Services;<br/>
                To maintain, improve, and personalize the Services;<br/>
                To communicate with you;<br/>
                For account and network security purposes;<br/>
                To maintain legal and regulatory compliance;<br/>
                To enforce compliance with our Terms and Conditions and this Policy;<br/>
                To protect you, others, and our business; and<br/>
                For our business purposes disclosed to you at the time we collect or receive the information, or otherwise with your consent.<br/>
                We describe our information sharing practices in the Privacy Policy above. In the previous twelve months, we may have shared certain categories of personal information with third parties, as that term is defined in the California Consumer Privacy Act, for business purposes. We may have shared your personal information with a merchant if you expressly directed us to do so. The information shared may include the following categories of personal information: (1) identifiers, such as a name and email address; and (2) commercial information, such as your history of orders from that merchant. We may have also enabled third parties that provide paid analytics and advertising services to us to place tracking technologies on our website. These providers may have been able to access the following categories of personal information: (1) device information and identifiers, such as IP address and unique advertising identifiers and cookies; and (2) connection and usage information, such as browsing history or app usage. You can opt out of such tracking technologies by following the instructions in Section 2 above.
                </p>

                <p>Do Not Track Disclosure. RushApp does not have a mechanism in place for responding to browser “do not track” signals or other similar mechanisms used to limit collection of information for use in Online Behavioral Advertising.</p>

                <p>12.   Nevada Residents</p>

                <p>Under Nevada law, certain Nevada consumers may opt out of the sale of “personally identifiable information” for monetary consideration to a person that person to license or sell such information to additional persons. “Personally identifiable information” includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online. We do not engage in such activity; however, if you are a Nevada resident who has purchased or leased goods or services from us, you may submit a request to opt out of any potential future sales under Nevada law by contacting privacy@RushApp.com. Please note we will take reasonable steps to verify your identity and the authenticity of the request. Once verified, we will maintain your request in the event our practices change.</p>

                <p>13.   International Users</p>

                <p>RushApp is based in the United States and, regardless of where you use our Services, the information collected as part of that use will be transferred to and maintained on servers located in the United States. Any information we obtain about you will be stored in accordance with U.S. privacy laws, regulations, and standards, which may not be equivalent to the laws in your country of residence. By using our Services, you consent to this collection, transfer, storage, and processing of information to and in the United States.</p>

                <p>14.  Contact Us</p>

                <p>If you have any questions or concerns relating to this Policy or our privacy practices please contact us at:&nbsp;&nbsp;privacy@rushapp.com or:</p>

                RushApp, Inc.<br/>
                1702 E Sparrow Knoll Ave<br/>
                Ellensburg, WA 98926<br/>
                <br/>
                Help Form: https://help.rushapp.com/consumers/s/contactsupport
            </div>
        </div>
    );
}

export default Privacy;