import React from "react";
import logo from "../resources/images/splash_1024.png";

function Home() {
    return (
        <div className="App">
            {/*<Navbar/>*/}
            <div className='splash-logo'>
                <img src={logo} alt='RushApp splash logo' />
            </div>
        </div>
    );
}

export default Home;