import './App.css';
import React from "react";
//import Navbar from "./components/layout/Navbar";
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from "./components/Home"
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";

function App() {
  return (
      <Router>
          <Switch>
              <Route path="/" exact component={() => <Home />} />
              <Route path="/contact" exact component={() => <Contact />} />
              <Route path="/privacy" exact component={() => <Privacy />} />
              <Route path="/terms" exact component={() => <Terms />} />
          </Switch>
      </Router>
  );
}

export default App;
