import React from "react";

function Terms() {
    return (
        <div className="terms">
            <div class="container" style={{margin : '15px'}}>
                <h1>Terms and Conditions - United States</h1>
                <h1>RushApp Consumers</h1>
                <h1>Terms and Conditions Agreement</h1>
                Effective: March 29, 2021<br/>

                <p>
                PLEASE READ THE TERMS AND CONDITIONS CAREFULLY.
                THE TERMS AND CONDITIONS (“AGREEMENT”) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND RUSHAPP, INC., A DELAWARE CORPORATION, AND ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY, “RUSHAPP,” “WE,” “US,” OR “OUR”).
                </p>

                <p>
                SECTION 12 OF THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE THE EFFECTIVE DATE OF THIS AGREEMENT.
                IN PARTICULAR, SECTION 12 SETS FORTH OUR ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION.
                UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT:
                (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, EXCEPT AS SET FORTH IN SECTION 12(G);
                AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.
                THE ARBITRATION AGREEMENT COULD AFFECT YOUR RIGHT TO PARTICIPATE IN PENDING PROPOSED CLASS ACTION LITIGATION.
                PLEASE SEE SECTION 12 FOR MORE INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.
                </p>

                <p>
                1. Acceptance of this Agreement<br/>
                RushApp, including its wholly-owned subsidiaries, provides an online marketplace connection, using web-based technology that connects you and other consumers, restaurants and/or other businesses and independent delivery contractors (“Contractors”).
                RushApp’s software permits consumers to place orders for food and/or other goods from various restaurants and businesses, either for delivery or pickup (the “Software”). Once a delivery order is made, the Software notifies Contractors that a delivery opportunity is available and the Software facilitates completion of the delivery to the consumer.
                Once a pickup order is made, the Software communicates with the customer regarding the availability of the order for pickup.
                RushApp is not a restaurant, delivery service, or food preparation business.
                </p>

                <p>
                If you access any of our websites located at https://www.rushapp.com/, install or use our RushApp mobile application, install or use any other software supplied by RushApp, or access any information, function, or service available or enabled by RushApp (each, a “Service” and collectively, the “Services”), or complete the RushApp account registration process, you, your heirs, assigns, and successors (collectively, “you” or “your”) hereby represent and warrant that:
                </p>

                <p>(a) you have read, understand, and agree to be bound by this Agreement;</p>

                <p>(b) you are of legal age in the jurisdiction in which you reside to form a binding contract with RushApp; and</p>

                <p>(c) you have the authority to enter into the Agreement personally and, if applicable, on behalf of any organization on whose behalf you have created an account and to bind such organization to the Agreement.</p>

                <p>
                The terms “User” and “Users” refer to all individuals and other persons who access or use the Services, including, without limitation, any organizations that register accounts or otherwise access or use the Services through their respective representatives.
                Except as otherwise provided in this Agreement, if you do not agree to be bound by the Agreement, you may not access or use the Services.
                </p>

                <p>
                2. Modifications<br/>
                Subject to Section 12(k) of this Agreement, RushApp reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Software or Services at any time, effective upon posting of an updated version of this Agreement through the Services.
                You should regularly review this Agreement, as your continued use of the Services after any such changes constitutes your agreement to such changes.
                </p>

                <p>
                3. Additional Terms and Policies<br/>
                By using the Services, you agree to be bound by this Agreement and acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with RushApp’s Privacy Policy, which is incorporated in this Agreement by reference.
                You also agree to abide by any additional RushApp policies for Users that are published on our website or mobile application.
                Certain features of our Services may be subject to additional terms and conditions, which are incorporated herein by reference.
                </p>

                <p>
                4. Rules and Prohibitions<br/>
                Without limiting other rules and prohibitions in this Agreement, by using the Services, you agree that:
                </p>

                <p>(a) You will only use the Services for lawful purposes; you will not use the Services for sending or storing any unlawful material or for deceptive or fraudulent purposes; and you will not engage in conduct that harms other Users, RushApp employees, or our community.</p>

                <p>(b) You will only use the Services in accordance with all applicable laws, including copyrights, trade secrets, or other rights of any third party, including privacy or publicity rights.</p>

                <p>(c) You will only access the Services using means explicitly authorized by RushApp.</p>

                <p>(d) You will not use another User’s account, impersonate any person or entity, or forge or manipulate headers or identifiers to disguise the origin of any content transmitted through the Services.</p>

                <p>(e) You will not use the Services to cause nuisance, annoyance or inconvenience.</p>

                <p>(f) You will not use the Services, or any content accessible through the Services, for any commercial purpose, including but not limited to contacting, advertising to, soliciting or selling to, any Merchant, User or Contractor, unless RushApp has given you prior permission to do so in writing.</p>

                <p>(g) You will not copy or distribute the Software or any content displayed through the Services, including Merchants’ menu content and reviews, for republication in any format or media.</p>

                <p>(h) You will not compile, directly or indirectly, any content displayed through the Services except for your personal, noncommercial use.</p>

                <p>(i) The information you provide to us when you register an account or otherwise communicate with us is accurate, you will promptly notify us of any changes to such information, and you will provide us with whatever proof of identity we may reasonably request.</p>

                <p>(j) You will keep secure and confidential your account password or any identification credentials we provide you which allows access to the Services.</p>

                <p>(k) You will use the Software and Services only for your own use and will not directly or indirectly resell, license or transfer the Software, Services or content displayed by the Services to a third party.</p>

                <p>(l) You will not use the Services in any way that could damage, disable, overburden or impair any RushApp server, or the networks connected to any RushApp server.</p>

                <p>(m) You will not attempt to gain unauthorized access to the Services and/or to any account, resource, computer system, and/or network connected to any RushApp server.</p>

                <p>(n) You will not probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures RushApp may use to prevent or restrict access to the Services or use of the Services or the content therein.</p>

                <p>(o) You will not deep-link to our websites or access our websites manually or with any robot, spider, web crawler, extraction software, automated process and/or device to scrape, copy, index, frame, or monitor any portion of our websites or any content on our websites.</p>

                <p>(p) You will not scrape or otherwise conduct any systematic retrieval of data or other content from the Services.</p>

                <p>(q) You will not try to harm other Users, RushApp, or the Services in any way whatsoever.</p>

                <p>(r) You will not engage in threatening, harassing, racist, sexist or any other behavior that RushApp deems inappropriate when using the Services.</p>

                <p>(s) You will report any errors, bugs, unauthorized access methodologies or any breach of our intellectual property rights that you uncover in your use of the Services.</p>

                <p>(t) You will not abuse our promotional or credit code system, including by redeeming multiple coupons at once or by opening multiple accounts to benefit from offers available only to first-time users.</p>

                <p>(u) You will not attempt to undertake any of the foregoing.</p>

                <p>
                In the event that we believe or determine that you have breached any of the aforementioned, we reserve the right to suspend and/or permanently deactivate your account at our sole discretion.
                </p>

                <p>
                5. Contractors and Merchants Are Independent<br/>
                You understand and agree that RushApp provides a technology platform connecting you with independent food service providers and others that provide the products offered through the Services (“Merchants”), and independent third-party contractors who provide delivery services (“Contractors”).
                You acknowledge and agree that RushApp does not itself prepare food or offer delivery services, and has no responsibility or liability for the acts or omissions of any Merchant or any Contractor.
                Merchant is the retailer; the services offered by RushApp pursuant to this Agreement do not include any retail services or any food preparation services.
                RushApp is not in the delivery business nor is it a common carrier.
                RushApp provides a technology platform facilitating the transmission of orders by Users to Merchants for pickup or delivery by Contractors.
                RushApp will not assess or guarantee the suitability, legality or ability of any Contractor or Merchant.
                You agree that RushApp is not responsible for the Merchants’ food preparation or the safety of the food or whether the photographs or images displayed through the Services accurately reflect the food prepared by the Merchants and/or delivered by the Contractor, and does not verify Merchants’ compliance with applicable laws or regulations.
                RushApp has no responsibility or liability for acts or omissions by any Merchant or Contractor.
                </p>

                <p>
                You agree that the goods that you purchase will be prepared by the Merchant you have selected, that title to the goods passes from the Merchant to you at the Merchant’s location, and that, for delivery orders, the Contractor will be directed by your instructions to transport the products to your designated delivery location.
                You agree that neither the Contractor nor RushApp holds title to or acquires any ownership interest in any goods that you order through the Services.
                </p>

                <p>
                6. User Account<br/>
                You may be required to register for an account to use parts of the Services.
                You must provide accurate, current, and complete information during the registration process and at all other times when you use the Services, and to update the information to keep it accurate, current, and complete.
                You are the sole authorized user of any account you create through the Services.
                You are solely and fully responsible for all activities that occur under your password or account.
                You agree that you shall monitor your account to prevent use by minors, and you will accept full responsibility for any unauthorized use of your password or your account.
                You may not authorize others to use your User status, and you may not assign or otherwise transfer your User account to any other person or entity.
                Should you suspect that any unauthorized party may be using your password or account, you will notify RushApp immediately.
                RushApp will not be liable and you may be liable for losses, damages, liability, expenses, and fees incurred by RushApp or a third party arising from someone else using your account, regardless of whether you have notified us of such unauthorized use.
                If you provide any information that is untrue, inaccurate, not current, or incomplete, or RushApp has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, RushApp has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
                We may enable or require you to use a single pair of login credentials to use the RushApp services.
                You agree not to create an account or use the Services if you have been previously removed by RushApp, or if you have been previously banned from use of the Services.
                </p>

                <p>
                7. User Content<br/>
                (a) User Content. RushApp may provide you with interactive opportunities through the Services, including, by way of example, the ability to post User ratings and reviews (collectively, “User Content”). You represent and warrant that you are the owner of, or otherwise have the right to provide, all User Content that you submit, post and/or otherwise transmit through the Services.
                You further represent and warrant that any User Content that you submit, post and/or otherwise transmit through the Services (i) does not violate any third-party right, including any copyright, trademark, patent, trade secret, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) does not contain material that is false, intentionally misleading, or defamatory; (iii) does not contain any material that is unlawful; (iv) does not violate any law or regulation; and (v) does not violate this Agreement.
                You hereby grant RushApp a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicenseable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use the User Content in connection with RushApp’s business and in all forms now known or hereafter invented (“Uses”), without notification to and/or approval by you.
                You further grant RushApp a license to use your username, first name and last initial, and/or other User profile information, including without limitation, your ratings history, to attribute User Content to you in connection with such Uses, without notification or approval by you.
                You agree that this license includes the right for other Users to access and use your User Content in conjunction with participation in the Services and as permitted through the functionality of the Services.
                In the interest of clarity, the license granted to RushApp herein shall survive termination of the Services or your account.
                RushApp reserves the right in its sole discretion to remove or disable access to any User Content from the Services, suspend or terminate your account at any time, or pursue any other remedy or relief available under equity or law if you post any User Content that violates this Agreement or any community or content guidelines we may publish or that we consider to be objectionable for any reason.
                You agree that RushApp may monitor and/or delete your User Content (but does not assume the obligation) for any reason in RushApp’s sole discretion.
                RushApp may also access, read, preserve, and disclose any information as RushApp reasonably believes is necessary to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce this Agreement, including investigation of potential violations hereof, (c) detect, prevent, or otherwise address fraud, security, or technical issues, (d) respond to User support requests, or (e) protect the rights, property or safety of RushApp, its Users and the public.
                </p>

                <p>
                (b) Feedback. You agree that any submission of any ideas, suggestions, and/or proposals to RushApp through its suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at your own risk and that RushApp has no obligations (including without limitation, obligations of confidentiality) with respect to such Feedback.
                You represent and warrant that you have all rights necessary to submit the Feedback and you hereby grant to RushApp a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicenseable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use such Feedback.
                </p>

                <p>
                (c) Ratings and Reviews. To the extent that you are asked to rate and post reviews of Merchants or other businesses (“Ratings” and “Reviews”), such Ratings and Reviews are considered User Content and are governed by this Agreement.
                Ratings and Reviews are not endorsed by RushApp and do not represent the views of RushApp or its affiliates.
                RushApp shall have no liability for Ratings and Reviews or for any claims for economic loss resulting from such Ratings and Reviews.
                Because we strive to maintain a high level of integrity with respect to Ratings and Reviews posted or otherwise made available through the Services, you agree that:
                (i) you will base any Rating or Review on first-hand experience with the Merchant or business;
                (ii) you will not provide a Rating or Review for any Merchant or business for which you have an ownership interest, employment relationship or other affiliation or for any of that company’s competitors;
                (iii) you will not submit a Rating or Review in exchange for payment, free food items, or other benefits from a Merchant or business;
                (iv) any Rating or Review you submit will comply with the Federal Trade Commission’s Guides Concerning the Use of Endorsements and Testimonials in Advertising;
                and (v) your Rating or Review will comply with the terms of this Agreement.
                If we determine, in our sole discretion, that any Rating or Review could diminish the integrity of the Ratings and Reviews or otherwise violates this Agreement, we may remove such User Content without notice.
                </p>

                <p>
                8. Communications with RushApp<br/>
                By creating a RushApp account, you electronically agree to accept and receive communications from RushApp, Contractors, or third parties providing services to RushApp including via email, text message, calls, and push notifications to the cellular telephone number you provided to RushApp.
                You understand and agree that you may receive communications generated by automatic telephone dialing systems and/or which will deliver prerecorded messages sent by or on behalf of RushApp, its affiliated companies and/or Contractor, including but not limited to communications concerning orders placed through your account on the Services.
                Message and data rates may apply.
                If you do not wish to receive promotional emails, text messages, or other communications, you may change your notification preferences by accessing Settings in your account.
                To opt out of receiving promotional text messages from RushApp, you must reply “STOP” from the mobile device receiving the messages. For purposes of clarity, delivery text messages between you and Dashers are transactional text messages, not promotional text messages.
                </p>

                <p>
                9. E-SIGN Disclosure<br/>
                By creating a RushApp account, you also consent to the use of an electronic record to document your agreement.
                You may withdraw your consent to the use of the electronic record by emailing RushApp at privacy@rushapp.com with “Revoke Electronic Consent” in the subject line.
                </p>

                <p>
                To view and retain a copy of this disclosure, you will need (i) a device (such as a computer or mobile phone) with a web browser and Internet access and (ii) either a printer or storage space on such device.
                For a free paper copy, or to update our records of your contact information, email RushApp at privacy@rushapp.com with contact information and your mailing address.
                </p>

                <p>
                10. Intellectual Property Ownership<br/>
                RushApp alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Software and the Services.
                This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Software or the Services, or any intellectual property rights owned by RushApp. RushApp names, RushApp logos, and the product names associated with the Software and Services are trademarks of RushApp or third parties, and no right or license is granted to use them.
                You agree that you will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Software or the Services.
                </p>

                <p>
                11. Payment Terms<br/>
                (a) Prices & Charges. You understand that: (a) the prices for menu or other items displayed through the Services may differ from the prices offered or published by Merchants for the same menu or other items and/or from prices available at third-party websites and that such prices may not be the lowest prices at which the menu or other items are sold;
                (b) RushApp has no obligation to itemize its costs, profits or margins when publishing such prices;
                and (c) RushApp reserves the right to change such prices at any time, at its discretion.
                For certain transactions, the subtotals shown at checkout are estimates that may be higher or lower depending on the final in- store totals.
                In those situations, RushApp reserves the right to charge your payment method the final price after checkout.
                You are liable for all transaction taxes on the Services provided under this Agreement (other than taxes based on RushApp’s income).
                In the event that the charge to your payment method may incorrectly differ from the total amount, including subtotal, fees, and gratuity, displayed to you at checkout and/or after gratuity is selected, RushApp reserves the right to make an additional charge to your payment method after the initial charge so that the total amount charged is consistent with the total amount displayed to you at checkout and/or after gratuity is selected.
                All payments will be processed by RushApp or its payments processor, using the preferred payment method designated in your account.
                If your payment details change, your card provider may provide us with updated card details. We may use these new details or details from other cards on file in order to help prevent any interruption to your Use of the Services.
                If you would like to use a different payment method or if there is a change in payment method, please update your billing information.
                </p>

                <p>
                (b) No Refunds.
                Charges paid by you for completed and delivered orders are final and non-refundable.
                RushApp has no obligation to provide refunds or credits, but may grant them, in each case in RushApp’s sole discretion.
                </p>

                <p>
                (c) Promotional Offers and Credits.
                RushApp, at its sole discretion, may make promotional offers with different features and different rates to any User.
                These promotional offers are subject to the terms of this Agreement and may be valid only for certain Users as indicated in the offer.
                You agree that promotional offers: (i) may only be used by the intended audience, for the intended purpose, and in a lawful manner;
                (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public, unless expressly permitted by RushApp;
                (iii) are subject to the specific terms that RushApp establishes for such promotional offer;
                (iv) cannot be redeemed for cash or cash equivalent;
                and (v) are not valid for use after the date indicated in the offer or in RushApp’s Terms and Conditions for Promotional Offers and Credits.
                RushApp reserves the right to withhold or deduct credits or benefits obtained through a promotion in the event that RushApp determines or believes that the redemption of the promotion or receipt of the credit or benefit was in error, fraudulent, illegal, or in violation of the applicable promotion terms or this Agreement.
                RushApp reserves the right to modify or cancel an offer at any time.
                RushApp’s Terms and Conditions for Promotional Offers and Credits (the current version of which is available at http://drd.sh/rtb7p2/ and which is incorporated in this Agreement by reference) apply to all promotional offers.
                You agree that we may change RushApp’s Terms and Conditions for Promotional Offers and Credits at any time.
                RushApp may also offer gratuitous credits, which can be used for the Services.
                Any credit issued by RushApp is valid for 6 months from the date of issue except to the extent prohibited under applicable law and may not be redeemed for cash or cash equivalent.
                Upon expiration, credits will be removed from your account.
                Expired credits are no longer redeemable and cannot be used towards any order.
                Credits issued through a User’s RushApp account may only be redeemed through that respective brand’s Services.
                </p>

                <p>
                (d) Fees for Services.
                RushApp may change the fees for our Services as we deem necessary or appropriate for our business, including but not limited to Delivery Fees, Service Fees, Small Order Fees, and Surge Fees.
                RushApp may also charge merchants fees on orders that you place through the Services, including commissions and other fees, and may change those merchant fees as we deem necessary or appropriate for our business or to comply with applicable law.
                </p>

                <p>
                (e) Referral Program.
                RushApp’s Referral Program Terms and Conditions are available at https://www.rushapp.com/referral-terms/ (“Referral Program”).
                Under the Referral Program, RushApp offers its registered Users in good standing the opportunity to earn gratuitous RushApp credits as promotional rewards by inviting their eligible friends to register as new RushApp Users and place their initial order through the Services by using a unique referral ID link (“Personal Link”).
                For each Qualified Referral (as defined in the Referral Program) generated through a User’s Personal Link, the User may receive a gratuitous credit as specified on RushApp’s Referral Program page.
                You agree that we may change the terms and conditions of the Referral Program or terminate the Referral Program at any time.
                </p>

                <p>
                (f) DashPass Subscriptions.
                </p>

                <p>
                General: RushPass is an automatically renewing subscription requiring recurring payments.
                A RushPass subscription grants you access to reduced fees on orders placed through the Services for eligible restaurants with a minimum subtotal as stated when you sign up (excluding taxes and tips) (“Reduced Fees”).
                Reduced Fees and other RushPass benefits may be redeemed only at eligible restaurants, as indicated through the Services.
                RushApp reserves the right to change whether a restaurant is eligible for RushPass at any time with or without notice.
                RushPass orders are subject to delivery driver and geographic availability, and taxes may apply to the cost of the items you order. You may provide an optional gratuity.
                Depending on the conditions as stated when you sign up, Service Fees and other fees may apply.
                We reserve the right to add and modify fees as discussed in this Section.
                </p>

                <p>
                RushPass is offered for a monthly or yearly fee payable at the start of the relevant period.
                By signing up for RushPass and providing RushApp with your payment account information, you agree that:
                (a) you will be charged your first RushPass subscription fee and any applicable taxes on the date you purchase your RushPass subscription;
                (b) you authorize RushApp and its service providers to store your payment method for the purpose of executing future RushPass auto-renewal transactions;
                (c) UNLESS YOU CANCEL, BY DEFAULT (AND WITH PRIOR NOTICE ONLY TO THE EXTENT REQUIRED BY APPLICABLE LAW), YOUR RUSHPASS SUBSCRIPTION WILL AUTOMATICALLY RENEW AT THE END OF THE THEN-CURRENT SUBSCRIPTION PERIOD, and
                (d) AT THE TIME OF RENEWAL, RUSHAPP WILL AUTOMATICALLY CHARGE THE THEN-CURRENT RUSHPASS FEE AND ANY APPLICABLE TAXES TO AN ELIGIBLE PAYMENT METHOD THAT WE HAVE ON FILE FOR YOU.
                If your payment details change, your card provider may provide us with updated card details.
                We may use these new details or details from other cards on file in order to help prevent any interruption to your RushPass subscription.
                If you would like to use a different payment method or if there is a change in payment method, please update your billing information.
                </p>

                <p>
                Corporate RushPass Subscriptions:
                Notwithstanding any other provision in these terms, you may be eligible for a complimentary RushPass subscription when your employer registers for a Corporate RushPass Subscription.
                If you have a complimentary RushPass subscription through your employer, your account will begin and end in accordance with the terms of your employer’s corporate subscription unless your subscription is otherwise cancelled.
                To receive the subscription benefits, you must register using your corporate email address and be an authorized user added by your employer, and then click the activation button.
                </p>

                <p>
                Trial or Promotional Subscriptions:
                From time to time, RushApp offers some customers trial or other promotional subscriptions to RushPass.
                Such trial or promotional subscriptions are subject to this Agreement and the RushApp Terms and Conditions for Promotional Offers and Credits except as otherwise stated in the promotional offer.
                When your free trial period has expired, your subscription will automatically convert into a paid RushPass subscription, and RushApp will bill you the applicable fee.
                If you cancel RushPass before the trial period has expired, RushApp will not charge you for the RushPass subscription.
                If you purchase a RushPass subscription with a promotional code, each time your RushPass subscription renews, you will be charged the full billing amount.
                Only one trial or promotional subscription is available per household.
                If your RushPass subscription is ever terminated for any reason, you shall not be eligible for a free trial on any subsequent RushPass subscription.
                </p>

                <p>
                Cancellations:
                You can cancel your RushPass subscription at any time through the Services.
                Instructions on how to cancel are available here.
                You must cancel at least one (1) day before the next-scheduled subscription renewal date to avoid being charged for the next subscription period (e.g., if renewal date is January 10, you must cancel by 11:59:59 pm PT on January 9).
                </p>

                <p>
                If you participated in a free trial or other promotional subscription period for RushPass, you may cancel within the first 48 hours of your paid RushPass subscription and receive a full refund of your RushPass fee (as applicable).
                </p>

                <p>
                For both monthly and annual subscribers, if you cancel your RushPass subscription within the first 48 hours and have not placed a RushPass-eligible order during that period, RushApp may, in its sole discretion, refund your RushPass fee.
                </p>

                <p>
                If you cancel your RushPass subscription at any other time, you will not receive a refund, but you can continue to enjoy the Reduced Fees and other RushPass benefits through the end of your then-current subscription period.
                </p>

                <p>
                RushPass Benefits for Chase Cardmembers:
                Chase cardmembers are eligible for certain RushPass benefits when an eligible Chase credit card is added to either account.
                The specific benefits depend upon which Chase credit card is added to your account.
                These benefits are subject to this Agreement and the RushApp Terms and Conditions for Promotional Offers and Credits as well as the following card-specific terms:
                </p>

                <p>
                Chase Sapphire Reserve®<br/>
                Chase Sapphire® & Chase Sapphire Preferred®<br/>
                Chase Freedom<br/>
                Chase Slate<br/>
                Additional information about the RushPass benefits for Chase cardmembers can be found here.
                </p>

                <p>
                Updates and Changes:
                The RushPass prices and features may change in the future.
                If RushApp changes the monthly or annual fee charged for a RushPass subscription, RushApp will notify you and provide you with the opportunity to change your subscription before RushPass is renewed for another subscription term.
                Your continued use of the Services after the change becomes effective will constitute your acceptance of the fee change.
                If you do not wish to continue subscribing with the new fees, you may cancel your RushPass subscription within the specified notice period.
                Any RushPass subscriptions will be subject to the terms and conditions in effect at the time you sign up or your subscription renews.
                RushApp may also make such changes with respect to current RushPass subscriptions.
                In that case, RushApp will provide you with notice via email of the changes and when those changes will take effect.
                If you disagree with the changes to your current RushPass subscription terms and conditions, you may cancel your RushPass subscription and receive a refund of your subscription fee on a pro rata basis calculated from the end of the month during which you cancel the subscription.
                </p>

                <p>
                No Transfer or Assignments & Cancellations by RushApp:
                Your RushPass subscription cannot be transferred or assigned. RushApp reserves the right to accept, refuse, suspend, or cancel your RushPass subscription at any time in its sole discretion.
                If RushApp cancels your RushPass subscription, you will receive a refund of your subscription fee on a pro rata basis calculated from the end of the month during which your subscription was cancelled, unless RushApp terminates your account or your RushPass subscription because it determines, in its sole discretion, that your actions or your use of the Services violates this Agreement or has harmed another User.
                </p>

                <p>
                (g) Gift Cards.
                Except as provided below, Gift Cards may be redeemable towards eligible orders placed on www.rushapp.com and RushApp apps in the United States.
                But if you have a Gift Card that was purchased and used September 15, 2020, you must use any remaining balance of that Gift Card on the service where the card was first redeemed.
                Gift Cards are made available and provided by RushApp, Inc. Gift Cards are not redeemable for cash except when required by applicable law.
                For more information on the Gift Card Terms and Conditions, visit rusherhelp.rushapp.com/rushapp-gift-cards.
                </p>

                <p>
                12. Dispute Resolution.
                </p>

                <p>
                PLEASE READ THE FOLLOWING SECTION CAREFULLY.
                IT REQUIRES YOU TO ARBITRATE DISPUTES WITH RUSHAPP AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF.
                THIS SECTION 12 OF THIS AGREEMENT SHALL BE REFERRED TO AS THE “ARBITRATION AGREEMENT”.<br/>
                (a) Scope of Arbitration Agreement.
                You agree that any dispute or claim relating in any way to your access or use of the Services as a User of the Services, to any advertising or marketing communications regarding RushApp or the Services, to any products or services sold or distributed through the Services that you received as a User of our Services, or to any aspect of your relationship or transactions with RushApp as a User of our Services will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify, so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis;
                and (2) you or RushApp may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).
                This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted before the Effective Date of this Agreement.
                </p>

                <p>
                CASES HAVE BEEN FILED AGAINST RUSHAPP—AND OTHERS MAY BE FILED IN THE FUTURE—THAT ATTEMPT TO ASSERT CLASS ACTION CLAIMS, AND BY ACCEPTING THIS ARBITRATION AGREEMENT YOU ELECT NOT TO PARTICIPATE IN SUCH CASES.
                </p>

                <p>
                IF YOU AGREE TO ARBITRATION WITH RUSHAPP, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN ANY SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUIT.
                INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS AGAINST RUSHAPP IN AN INDIVIDUAL ARBITRATION PROCEEDING.
                IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.
                </p>

                <p>
                (b) Informal Resolution.
                You and RushApp agree that good-faith informal efforts to resolve disputes often can result in a prompt, low-cost and mutually beneficial outcome.
                You and RushApp therefore agree that, before either you or RushApp demands arbitration against the other, we will personally meet and confer, via telephone or videoconference, in a good-faith effort to resolve informally any claim covered by this mutual Arbitration Agreement.
                If you are represented by counsel, your counsel may participate in the conference, but you shall also fully participate in the conference.
                The party initiating the claim must give notice to the other party in writing of its, his, or her intent to initiate an informal dispute resolution conference, which shall occur within 60 days after the other party receives such notice, unless an extension is mutually agreed upon by the parties.
                To notify RushApp that you intend to initiate an informal dispute resolution conference, email Informal.Resolution@rushapp.com, providing your name, telephone number associated with your RushApp account (if any), the email address associated with your RushApp account, and a description of your claim.
                In the interval between the party receiving such notice and the informal dispute resolution conference, the parties shall be free to attempt to resolve the initiating party’s claims.
                Engaging in an informal dispute resolution conference is a requirement that must be fulfilled before commencing arbitration.
                The statute of limitations and any filing fee deadlines shall be tolled while the parties engage in the informal dispute resolution process required by this paragraph.
                </p>

                <p>
                (c) Arbitration Rules and Forum.
                This Arbitration Agreement is governed by the Federal Arbitration Act (“FAA”) in all respects.
                If for whatever reason the rules and procedures of the FAA cannot apply, the state law governing arbitration agreements in the state in which you reside shall apply.
                Before a party may begin an arbitration proceeding, that party must send notice of an intent to initiate arbitration and certifying completion of the informal dispute resolution conference pursuant to paragraph 12(b).
                If this notice is being sent to RushApp, it must be sent by email to the counsel who represented RushApp in the informal dispute resolution process, or if there was no such counsel then by mail to General Counsel, at 303 2nd Street, Suite 800, San Francisco, CA, 94107.
                The arbitration will be conducted by ADR Services, Inc. under its rules and pursuant to the terms of this Agreement.
                Arbitration demands filed with ADR Services, Inc. must include (1) the name, telephone number, mailing address, and e-mail address of the party seeking arbitration;
                (2) a statement of the legal claims being asserted and the factual bases of those claims;
                (3) a description of the remedy sought and an accurate, good-faith calculation of the amount in controversy, enumerated in United States Dollars (any request for injunctive relief or attorneys’ fees shall not count toward the calculation of the amount in controversy unless such injunctive relief seeks the payment of money);
                and (4) the signature of the party seeking arbitration.
                Disputes shall be subject to ADR Services, Inc.’s most current version of its Arbitration Rules, available as of December 21, 2020 at https://www.adrservices.com/services/arbitration-rules or by calling ADR Services, Inc. at 310-201-0010.
                The fees that shall apply to arbitrations administered by ADR Services, Inc. are set forth on ADR Services, Inc.’s website, available as of December 21, 2020 at https://www.adrservices.com/rate-fee-schedule/.
                Specifically, the fees set forth in ADR Services, Inc.’s Mass Employment Arbitration Fee Schedule shall apply when twenty (20) or more arbitration claims are filed which:
                (1) involve the same or similar parties;
                (2) are based on the same or similar claims which arise from the same or substantially identical transactions, incidents, or events requiring the determination of the same or substantially identical questions of law or fact;
                and (3) involve the same or coordinated counsel for the parties.
                In all other circumstances, the fees set forth in ADR Services, Inc.’s General Fee Schedule shall apply, except that RushApp will pay the portion of the initial case opening fees (if any) that exceeds the filing fee to file the case in a court of competent jurisdiction embracing the location of the arbitration.
                Payment of all filing, administration, and arbitration fees will be governed by ADR Services, Inc.’s rules.
                If the arbitrator finds that you cannot afford to pay ADR Services, Inc.’s filing, administrative, hearing and/or other fees and cannot obtain a waiver of fees from ADR Services, Inc., RushApp will pay them for you.
                If ADR Services, Inc. is not available to arbitrate, the parties will mutually select an alternative arbitral forum.
                You may choose to have the arbitration conducted by telephone, video conference, based on written submissions, or in person in the county where you live or at another mutually agreed location.
                </p>

                <p>
                (d) Arbitrator Powers.
                The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable.
                All disputes regarding the payment of arbitrator or arbitration-organization fees including the timing of such payments and remedies for nonpayment, shall be determined exclusively by an arbitrator, and not by any court.
                The arbitration will decide the rights and liabilities, if any, of you and RushApp.
                Except as expressly agreed to in Section 12(g) of this Agreement, the arbitration proceeding will not be consolidated with any other matters or joined with any other proceedings or parties.
                The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute.
                The arbitrator will have the authority to award, on an individual basis, monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and this Agreement (including this Arbitration Agreement).
                The arbitrator will issue a written statement of decision describing the essential findings and conclusions on which any award (or decision not to render an award) is based, including the calculation of any damages awarded.
                The award shall be binding only among the parties and shall have no preclusive effect in any other arbitration or other proceeding involving a different party.
                The arbitrator shall follow the applicable law. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.
                The arbitrator’s decision is final and binding on you and RushApp.
                </p>

                <p>
                (e) Waiver of Jury Trial. 
                YOU AND RUSHAPP WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL.
                You and RushApp are instead electing to have claims and disputes resolved by arbitration, except as specified in Section 12(a) above.
                There is no judge or jury in arbitration, and court review of an arbitration award is limited.
                </p>
                    
                <p>
                (f) Waiver of Class or Consolidated Actions.
                EXCEPT AS EXPRESSLY AGREED TO IN SECTION 12(G) OF THIS AGREEMENT, YOU AND RUSHAPP AGREE TO WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS EXCEPT AS SET FORTH IN SECTION 12(G).
                CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER EXCEPT AS SET FORTH IN SECTION 12(G).
                If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable with respect to a particular claim or dispute, neither you nor RushApp is entitled to arbitration of such claim or dispute.
                Instead, all such claims and disputes will then be resolved in a court as set forth in Section 20.
                This provision does not prevent you or RushApp from participating in a class-wide settlement of claims.
                </p>

                <p>
                (g) Batch Arbitrations.
                To increase efficiency of resolution, in the event 100 or more similar arbitration demands against RushApp, presented by or with the assistance of the same law firm or organization, are submitted to an arbitration provider selected in accordance with the rules described above within a 30-day period, the arbitration provider shall (i) group the arbitration demands into batches of no more than 100 demands per batch (plus, to the extent there are less than 100 arbitration demands left over after the batching described above, a final batch consisting of the remaining demands);
                and (ii) provide for resolution of each batch as a single arbitration with one set of filing and administrative fees and one arbitrator assigned per batch.
                You agree to cooperate in good faith with RushApp and the arbitration provider to implement such a batch approach to resolution and fees.
                </p>

                <p>
                (h) Opt Out. RushApp’s updates to these Terms and Conditions do not provide a new opportunity to opt out of the Arbitration Agreement for customers or Users who had previously agreed to a version of RushApp’s Terms and Conditions and did not validly opt out of arbitration.
                RushApp will continue to honor the valid opt outs of customers or Users who validly opted out of the Arbitration Agreement in a prior version of the Terms and Conditions.
                If you are a customer or User who creates a RushApp account for the first time on or after November 10, 2020, you may opt out of this Arbitration Agreement.
                If you do so, neither you nor RushApp can force the other to arbitrate as a result of this Agreement.
                To opt out, you must notify RushApp in writing no later than 30 days after first becoming subject to this Arbitration Agreement.
                Your notice must include your name and address, your RushApp username (if any), the email address you currently use to access your RushApp account (if you have one), and a CLEAR statement that you want to opt out of this Arbitration Agreement.
                You must send your opt-out notice to: opt-out@rushapp.com.
                If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you.
                </p>

                <p>
                (i) No Effect on Independent Contractor Agreement.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, NOTHING IN THIS AGREEMENT SHALL SUPERSEDE, AMEND, OR MODIFY THE TERMS OF ANY SEPARATE AGREEMENT(S) BETWEEN YOU AND RUSHAPP RELATING TO YOUR WORK AS AN EMPLOYEE OR INDEPENDENT CONTRACTOR, INCLUDING WITHOUT LIMITATION, ANY INDEPENDENT CONTRACTOR AGREEMENT GOVERNING YOUR SERVICES AS A CONTRACTOR.
                FOR THE AVOIDANCE OF DOUBT, IF YOU ARE A CONTRACTOR, OPTING-OUT OF THE ARBITRATION AGREEMENT SET FORTH IN THIS SECTION 12 HAS NO EFFECT ON YOUR AGREEMENT TO ARBITRATE DISPUTES COVERED BY YOUR INDEPENDENT CONTRACTOR AGREEMENT WITH RUSHAPP.
                </p>

                <p>
                (j) Survival. This Arbitration Agreement will survive any termination of your relationship with RushApp.
                </p>

                <p>
                (k) Modification.
                Notwithstanding any provision in the Agreement to the contrary, we agree that if RushApp makes any future material change to this Arbitration Agreement, it will not apply to any individual claim(s) that you had already provided notice of to RushApp.
                </p>

                <p>
                13. Third-Party Interactions.
                </p>

                <p>
                (a) Third-Party Websites, Applications and Advertisements.
                The Services may contain links to third-party websites (“Third-Party Websites”) and applications (“Third-Party Applications”) and advertisements (“Third-Party Advertisements”) (collectively, “Third-Party Websites & Advertisements”).
                When you click on a link to a Third-Party Website, Third-Party Application or Third-Party Advertisement, RushApp will not warn you that you have left RushApp’s website or Services and will not warn you that you are subject to the terms and conditions (including privacy policies) of another website or destination.
                Such Third-Party Websites & Advertisements are not under the control of RushApp.
                RushApp is not responsible for any Third-Party Websites, Third-Party Applications or any Third-Party Advertisements.
                RushApp does not review, approve, monitor, endorse, warrant, or make any representations with respect to such Third-Party Websites & Advertisements, or their products or services.
                You use all links in Third-Party Websites & Advertisements at your own risk.
                You should review applicable terms and policies, including privacy and data gathering practices of any Third-Party Websites or Third-Party Applications, and make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.
                </p>

                <p>
                (b) App Stores.
                You acknowledge and agree that the availability of the Software and the Services is dependent on the third party from which you received the application license, e.g., the Apple iPhone or Android app stores (“App Store”).
                You acknowledge and agree that this Agreement is between you and RushApp and not with the App Store.
                RushApp, not the App Store, is solely responsible for the Software and the Services, including the mobile application(s), the content thereof, maintenance, support services and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance or intellectual property infringement).
                In order to use the Software and the Services, you must have access to a wireless network, and you agree to pay all fees associated with such access.
                You also agree to pay all fees (if any) charged by the App Store in connection with the Software or the Services.
                You agree to comply with, and your license to use the Software and the Services is conditioned upon your compliance with, all applicable third-party terms of agreement (e.g., the App Store’s terms and policies) when using the Software or the Services.
                You represent and warrant that you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and you represent and warrant that you are not listed on any U.S. Government list of prohibited or restricted parties.
                You acknowledge and agree that each App Store (and its affiliates) is an intended third-party beneficiary of this Agreement and has the right to enforce the terms and conditions of this Agreement.
                </p>

                <p>
                14. Social Media Guidelines.<br/>
                RushApp maintains certain social media pages for the benefit of the RushApp community.
                By posting, commenting, or otherwise interacting with these pages, you agree to abide by our Social Media Community Guidelines.
                </p>

                <p>
                15. Transactions Involving Alcohol<br/>
                You may have the option to request delivery of alcohol products in some locations and from certain Merchants.
                If you receive your delivery in the United States, you agree that you will only order alcohol products if you are 21 years of age or older.
                If you receive your delivery in another country, you agree that you will only order alcohol products if you are of legal age to purchase alcohol products in the relevant jurisdiction.
                You also agree that, upon delivery of alcohol products, you will provide valid government-issued identification proving your age to the Contractor delivering the alcohol products and that the recipient will not be intoxicated when receiving delivery of such products.
                If you order alcohol products, you understand and acknowledge that neither RushApp nor the Contractor can accept your order of alcohol products, and the order will only be delivered if the Merchant accepts your order.
                The Contractor reserves the right to refuse delivery if you are not 21 years old, if you cannot provide a valid government issued ID, if the name on your ID does not match the name on your order, or you are visibly intoxicated.
                If the Contractor is unable to complete the delivery of alcohol products for one or more of these reasons, you are subject to a non-refundable $20 re-stocking fee.
                </p>

                <p>
                16. Indemnification<br/>
                You agree to indemnify and hold harmless RushApp and its officers, directors, employees, agents and affiliates (each, an “Indemnified Party”), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation, attorneys’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from (a) your User Content;
                (b) your misuse of the Software or Services;
                (c) your breach of this Agreement or any representation, warranty or covenant in this Agreement;
                or (d) your violation of any applicable laws, rules or regulations through or related to the use of the Software or Services.
                In the event of any claim, allegation, suit or proceeding alleging any matter potentially covered by the agreements in this Section, you agree to pay for the defense of the Indemnified Party, including reasonable costs and attorneys’ fees incurred by the Indemnified Party.
                RushApp reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with RushApp in asserting any available defenses.
                This provision does not require you to indemnify any Indemnified Party for any unconscionable commercial practice by such party, or for such party’s negligence, fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Software or Services.
                You agree that the provisions in this section will survive any termination of your account, this Agreement, or your access to the Software and/or Services.
                </p>

                <p>
                17. Disclaimer of Warranties<br/>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT OF LAW, YOUR USE OF THE SOFTWARE AND SERVICES IS ENTIRELY AT YOUR OWN RISK.
                CHANGES ARE PERIODICALLY MADE TO THE SOFTWARE AND SERVICES AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU.
                THE SOFTWARE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                RUSHAPP MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE SOFTWARE OR SERVICES, OR THE SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS.
                </p>

                <p>
                RUSHAPP DOES NOT WARRANT THAT THE SOFTWARE OR SERVICES WILL OPERATE ERROR-FREE OR THAT THE SOFTWARE OR SERVICES ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE.
                IF YOUR USE OF THE SOFTWARE OR SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, RUSHAPP SHALL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.
                </p>

                <p>
                18. Internet Delays<br/>
                The Software and Services may be subject to limitations, delays, and other problems inherent in the use of the Internet and electronic communications.
                Except as set forth in RushApp’s Privacy Policy or as otherwise required by applicable law, RushApp is not responsible for any delays, delivery failures, or damage, loss or injury resulting from such problems.
                </p>

                <p>
                19. Breach And Limitation of Liability<br/>
                (a) General. You understand and agree that a key element of the Services and this Agreement is your and our mutual desire to keep the Services simple and efficient, and to provide the Software and Services at low cost.
                You understand and agree to the limitations on remedies and liabilities set forth in this Section 19 to keep the Software and Services simple and efficient, and costs low, for all Users.
                </p>

                <p>
                (b) Cap on Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, RUSHAPP’S AGGREGATE LIABILITY SHALL NOT EXCEED THE GREATER OF AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU TO RUSHAPP IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM.
                THIS CAP ON LIABILITY SHALL APPLY FULLY TO RESIDENTS OF NEW JERSEY.
                </p>

                <p>
                (c) Disclaimer of Certain Damages.
                TO THE FULLEST EXTENT OF LAW, RUSHAPP SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOST PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, AND LOSS OF DATA, REVENUE, USE AND ECONOMIC ADVANTAGE).
                THE FOREGOING DISCLAIMER OF PUNITIVE AND EXEMPLARY DAMAGES, AND THE ENTIRE DISCLAIMER OF DAMAGES FOR PERSONAL INJURY OR PROPERTY DAMAGE, OR FOR ANY INJURY CAUSED BY RUSHAPP’S FRAUD OR FRAUDULENT MISREPRESENTATION, SHALL NOT APPLY TO USERS WHO RESIDE IN THE STATE OF NEW JERSEY.
                </p>

                <p>
                20. Exclusive Venue<br/>
                To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you and RushApp agree that all claims and disputes arising out of or relating to the Agreement will be litigated exclusively in the state or federal courts located in San Francisco County if you are a California citizen or resident, and in the United States District Court for the District in which you reside if you are not a California citizen or resident.
                </p>

                <p>
                21. Termination<br/>
                If you violate this Agreement, RushApp may respond based on a number of factors including, but not limited to, the egregiousness of your actions and whether a pattern of harmful behavior exists.
                </p>

                <p>
                In addition, at its sole discretion, RushApp may modify or discontinue the Software or Service, or may modify, suspend or terminate your access to the Software or the Services, for any reason, with or without notice to you and without liability to you or any third party.
                In addition to suspending or terminating your access to the Software or the Service, RushApp reserves the right to take appropriate legal action, including without limitation, pursuing civil, criminal or injunctive redress.
                Even after your right to use the Software or the Services is terminated, this Agreement will remain enforceable against you.
                All provisions which by their nature should survive to give effect to those provisions shall survive the termination of this Agreement.
                </p>

                <p>
                22. Procedure for Making Claims of Copyright Infringement.<br/>
                It is RushApp’s policy to terminate membership privileges of any User who repeatedly infringes copyright upon prompt notification to RushApp by the copyright owner or the copyright owner’s legal agent.
                Without limiting the foregoing, if you believe that your work has been copied and posted on the Services in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information:
                (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
                (b) a description of the copyrighted work that you claim has been infringed;
                (c) a description of the location on the Services of the material that you claim is infringing;
                (d) your address, telephone number and e-mail address;
                (e) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law;
                and (f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                Contact information for RushApp’s Copyright Agent for notice of claims of copyright infringement is as follows: General Counsel, RushApp, Inc., 303 2nd St, Suite 800, San Francisco, CA 94107.
                </p>

                <p>
                23. General<br/>
                (a) No Joint Venture or Partnership. No joint venture, partnership, employment, or agency relationship exists between you, RushApp or any third-party provider as a result of this Agreement or use of the Software or Services.
                </p>

                <p>
                (b) Choice of Law. This Agreement is governed by the laws of the State of Delaware consistent with the Federal Arbitration Act, without giving effect to any principles that provide for the application of the law of any other jurisdiction.
                </p>

                <p>
                (c) Severability. Except as otherwise provided in this Agreement, if any provision of this Agreement is found to be invalid, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.
                </p>

                <p>
                (d) Consumer Complaints.
                In accordance with California Civil Code § 1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
                </p>

                <p>
                (e) Accessing and Downloading the Application from iTunes.
                The following applies to any Software accessed through or downloaded from the Apple App Store (an “App Store Sourced Application”):
                </p>

                <p>
                (1) You acknowledge and agree that (i) the Agreement is concluded between you and RushApp only, and not Apple, and (ii) RushApp, not Apple, is solely responsible for the App Store Sourced Application and content thereof.
                Your use of the App Store Sourced Application must comply with the App Store Terms of Service.
                </p>

                <p>
                (2) You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Application.
                </p>

                <p>
                (3) In the event of any failure of the App Store Sourced Application to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price, if any, for the App Store Sourced Application to you and to the fullest extent permitted by law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Application.
                As between RushApp and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of RushApp.
                </p>

                <p>
                (4) You and RushApp acknowledge that, as between RushApp and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the App Store Sourced Application or your possession and use of the App Store Sourced Application, including, but not limited to:
                (i) product liability claims;
                (ii) any claim that the App Store Sourced Application fails to conform to any applicable legal or regulatory requirement;
                and (iii) claims arising under consumer protection or similar legislation.
                </p>

                <p>
                (5) You and RushApp acknowledge that, in the event of any third-party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between RushApp and Apple, RushApp, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Terms.
                </p>

                <p>
                (6) You and RushApp acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of the Terms as related to your license of the App Store Sourced Application, and that, upon your acceptance of the terms and conditions of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms as related to your license of the App Store Sourced Application against you as a third-party beneficiary thereof.
                </p>

                <p>
                (7) Without limiting any other terms of the Terms, you must comply with all applicable third-party terms of agreement when using the App Store Sourced Application.
                </p>

                <p>
                (f) Notice.
                Where RushApp requires that you provide an e-mail address, you are responsible for providing RushApp with your most current e-mail address.
                In the event that the last e-mail address you provided to RushApp is not valid, or for any reason is not capable of delivering to you any notices required or permitted by this Agreement, RushApp’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice.
                You may give notice to RushApp through the following web form: https://help.rushapp.com/consumers/s/contactsupport.
                Such notice shall be deemed given on the next business day after such e-mail is actually received by RushApp.
                </p>

                <p>
                (g) Electronic Communications.
                For contractual purposes, you (1) consent to receive communications from RushApp in an electronic form;
                and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that RushApp provides to you electronically satisfy any legal requirement that such communications would satisfy if they were in writing.
                You agree to keep your contact information, including email address, current.
                This subparagraph does not affect your statutory rights.
                </p>

                <p>
                (h) Transfer and Assignment.
                This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by RushApp without restriction.
                Any attempted transfer or assignment in violation hereof shall be null and void.
                This Agreement binds and inures to the benefit of each party and the party’s successors and permitted assigns.
                </p>

                <p>
                (i) Entire Agreement.
                This Agreement is the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter.
                However, nothing in this Agreement shall supersede, amend, or modify the terms of any separate agreement(s) between you and RushApp relating to your work as an employee or independent contractor, including, without limitation, any Independent Contractor Agreement governing your efforts as a Contractor.
                </p>

                <p>24. Contact Information</p>
                <p>RushApp welcomes your questions or comments regarding the Terms:</p>

                RushApp, Inc.<br/>
                1702 E Sparrow Knoll Ave<br/>
                Ellensburg, WA 98926<br/>
                <br/>
                Help Form: https://help.rushapp.com/consumers/s/contactsupport<br/>
                <br/>
                Telephone Number: +1 (509) 306-9994
            </div>
        </div>
    );
}

export default Terms;